import { render, staticRenderFns } from "./TwoSidedBarChart.vue?vue&type=template&id=138e8a48&scoped=true"
import script from "./TwoSidedBarChart.vue?vue&type=script&lang=js"
export * from "./TwoSidedBarChart.vue?vue&type=script&lang=js"
import style0 from "./TwoSidedBarChart.vue?vue&type=style&index=0&id=138e8a48&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "138e8a48",
  null
  
)

export default component.exports