<template>
  <v-container fluid class="px-8">
    <div v-if="showAlert">
      <v-alert
        class="v-alert"
        v-model="showAlert"
        border="left"
        close-text="Close Alert"
        dismissible
        :type="alertType"
      >
        {{ alertMsg }}
      </v-alert>
    </div>

    <v-row class="ma-0 pa-0">
      <v-col cols="12">
        <v-row class="ma-0 pa-0" align="center">
          <v-col cols="auto" class="ma-0 pa-0">
            <span class="page-title">Ticketing</span>
            <a class="page-link" href="https://autotask.net/Mvc/Framework/Authentication.mvc/Authenticate" target="_blank">
              Autotask
              <v-icon color="#66B85A">mdi-open-in-new</v-icon>
            </a>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="auto" class="ma-0 pa-0">
              <span class="last_updated_title">Data Last Updated</span><br>
              <span class="last_updated_text">{{ data_info != null ? data_info : '-'}}</span>
          </v-col>
          <!-- <v-col cols="auto" class="ma-0 pa-0">
              <v-tooltip top>
                  <template v-slot:activator="{ on }">
                      <v-icon v-on="on">mdi-clock-outline</v-icon>
                  </template>
                  <TooltipDescription title="Data Last Updated." :desc_2="data_info"></TooltipDescription>
              </v-tooltip>
          </v-col> -->
        </v-row>
      </v-col>
      <v-col cols="12" class="ma-0 py-1">
          <v-chip @click="current_page='overview'" label class="data_breakdown_chip" :outlined="current_page != 'overview'" color="#66B85A">Overview</v-chip>
          <v-chip @click="current_page='details'" label class="data_breakdown_chip" :outlined="current_page != 'details'" color="#66B85A">Ticket Details</v-chip>
      </v-col>
      <v-col cols="6" v-if="current_page == 'overview'">
        <MainPageStat
          statistic_title_name="Open Tickets"
          :value="open_tickets"
        ></MainPageStat>
      </v-col>
      <v-col cols="6" v-if="current_page == 'overview'">
        <MainPageStat
          statistic_title_name="Critical/High Priority Open Tickets"
          :value="high_priority_open_tickets"
        ></MainPageStat>
      </v-col>
      <v-col cols="12" v-if="charts_config && current_page == 'overview'">
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
            <!-- <TwoSidedBarChart statistic_title_name="Tickets by type, state & Priority"></TwoSidedBarChart> -->
            <BaseChart
              :statistic_title_name="charts_config['charts']['TicketsByPriority']['chart_title']"
              :value="charts_config['charts']['TicketsByPriority']['chart_value']"
              :chart_type="charts_config['charts']['TicketsByPriority']['chart_type']"
            >
            </BaseChart>
          </v-col>
          <v-col cols="6">
            <!-- <TwoSidedBarChart statistic_title_name="Tickets by type, state & Priority"></TwoSidedBarChart> -->
            <BaseChart
              :statistic_title_name="charts_config['charts']['TicketsByStatus']['chart_title']"
              :value="charts_config['charts']['TicketsByStatus']['chart_value']"
              :chart_type="charts_config['charts']['TicketsByStatus']['chart_type']"
            >
            </BaseChart>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"  v-else-if="current_page == 'overview'">
        <v-row class="ma-0 pa-0">
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 260px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
          <v-col cols="6">
              <v-skeleton-loader type="image,image" style="height: 260px; overflow-y: hidden;"></v-skeleton-loader>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12"  v-if="current_page == 'details'">
        <StatisticsTable
          statistic_title_name="Open Tickets"
          :value="open_tickets_lst"
        ></StatisticsTable>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import MainPageStat from "../StatisticComponents/MainPageStat";
// import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
import StatisticsTable from "../StatisticComponents/StatisticsTable.vue";
import BaseChart from "../StatisticComponents/Charts/BaseChart.vue";
import api_caller from "@/javascript/chart_config_retrieval.js";
import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";

export default {
  name: "TicketingPage",
  props: [],
  data: () => ({
    alertMsg: null,
    alertType: null,
    showAlert: false,
    ticketing_raw_data: null,
    open_tickets_lst: [],
    old_tickets: [],
    open_tickets: null,
    open_tickets_by_priority: [],
    high_priority_open_tickets: null,
    charts_config: null,

    data_info: null,

    current_page: 'overview'
  }),
  components: {
    StatisticsTable,
    MainPageStat,
    BaseChart,
    // TooltipDescription
  },
  mounted() {
    this.getChartConfig();
  },
  methods: {
    getChartConfig: async function () {
      const token = await this.$auth.getTokenSilently();
      let charts_config = await api_caller
        .get_ticketing_charts_config(token)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          } else {
            return false;
          }
        })
        .catch((e) => {
          console.log(e);
          return false;
        });

      if (charts_config) {
        this.charts_config = charts_config;
        this.open_tickets_lst = this.charts_config['data']['OpenTicketsLst']['content']
        this.open_tickets = this.charts_config['data']['OpenTickets']['stat_value']
        this.high_priority_open_tickets = this.charts_config['data']['HighPriorityTickets']['stat_value'];
        this.data_info = charts_config['data_info']['data_last_updated'];
      } else {
        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },
    triggerAlert: function (type, message) {
      this.alertType = type ? type : "info";
      this.alertMsg = message;
      this.showAlert = true;
    }
  },
};
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title {
      font-family: "Gothic Bold" !important;
      font-size: 25pt !important;
    }
    .page-link {
      font-family: "Gothic" !important;
      font-size: 15pt !important;
      margin-left: 10px;
      color: #66b85a;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }
</style>
