<template>
  <div class="home">
    <BasePage />
  </div>
</template>

<script>
// @ is an alias to /src
import BasePage from '@/components/BasePage.vue'

export default {
  name: 'HomeView',
  components: {
    BasePage
  }
}
</script>
