function build_table_header(raw_header){
    let header = [];
    raw_header.forEach((item) => {
        header.push(item.value)
    })
    return header
}

function build_table_body(raw_body, header){
    let statistics_data = [];
    raw_body.forEach((raw_row) => {
        let row = [];
        header.forEach((column) => {
            row.push(raw_row[column])
        })
        statistics_data.push(row);
    })
    return statistics_data
}

function format_number(number){
    return number.toLocaleString();
}

export {build_table_header, build_table_body, format_number}