<template>
    <v-container fluid class="px-8">
        <div v-if="showAlert">
            <v-alert
                class="v-alert"
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-row class="ma-0 pa-0">
            <v-col cols="12">
                <v-row class="ma-0 pa-0" align="center">
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="page-title">Email Monitoring</span>
                        <a class="page-link" href="https://portal.mimecast.com/partner/common/login" target="_blank">
                            Mimecast
                            <v-icon color="#66B85A">mdi-open-in-new</v-icon>
                        </a>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="ma-0 pa-0">
                        <span class="last_updated_title">Data Last Updated</span><br>
                        <span class="last_updated_text">{{ data_info != null ? data_info : '-' }}</span>
                    </v-col>
                </v-row>
            </v-col>
            <!-- <v-col cols="12" class="ma-0 py-1">
                <v-chip @click="current_page='overview'" label class="data_breakdown_chip" :outlined="current_page != 'overview'" color="#66B85A">Overview</v-chip>
                <v-chip @click="current_page='quarantined'" label class="data_breakdown_chip" :outlined="current_page != 'quarantined'" color="#66B85A">Quarantined Emails</v-chip>
            </v-col> -->
            <v-col cols="12" align="center">
                <v-row class="ma-0 pa-0">
                    <v-col cols="4" class="ma-0 pa-0">
                        <MainPageStat statistic_title_name="Held Emails" :value="held_emails"></MainPageStat>
                        <!-- <TimeSeriesChart statistic_title_name="Manual Envelope Rejections" :value="email_traffic" short="true"></TimeSeriesChart> -->
                    </v-col>
                    <v-col cols="4" class="ma-0 pa-0">
                        <MainPageStat statistic_title_name="Rejected Emails" :value="rejected_emails"></MainPageStat>
                        <!-- <TimeSeriesChart statistic_title_name="Sender Failed to Retry" :value="email_traffic" short="true"></TimeSeriesChart> -->
                    </v-col>
                    <v-col cols="4" class="ma-0 pa-0">
                        <MainPageStat statistic_title_name="Bounced Emails" :value="bounced_emails"></MainPageStat>
                        <!-- <TimeSeriesChart statistic_title_name="IP Found in RBL" :value="email_traffic" short="true"></TimeSeriesChart> -->
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" align="center" v-if="charts_config">
                <v-row class="ma-0 pa-0">
                    <v-col cols="6">
                        <BaseChart
                            :statistic_title_name="charts_config['charts']['EmailActivityInPastWeek']['chart_title']"
                            :value="charts_config['charts']['EmailActivityInPastWeek']['chart_value']"
                            :chart_type="charts_config['charts']['EmailActivityInPastWeek']['chart_type']"
                        >    
                        </BaseChart>
                    </v-col>
                    <v-col cols="6">
                        <BaseChart
                            :statistic_title_name="charts_config['charts']['RejectionsByType']['chart_title']"
                            :value="charts_config['charts']['RejectionsByType']['chart_value']"
                            :chart_type="charts_config['charts']['RejectionsByType']['chart_type']"
                        ></BaseChart>
                    </v-col>
                    <v-col cols="12">
                        <BaseChart
                            :statistic_title_name="charts_config['charts']['EmailTraffic']['chart_title']"
                            :value="charts_config['charts']['EmailTraffic']['chart_value']"
                            :chart_type="charts_config['charts']['EmailTraffic']['chart_type']"
                        ></BaseChart>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="12" align="center" v-else>
                <v-row class="ma-0 pa-0">
                    <v-col cols="6" >
                        <v-skeleton-loader type="image,image" style="height: 260px; overflow-y: hidden;"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="6">
                        <v-skeleton-loader type="image,image" style="height: 260px; overflow-y: hidden;"></v-skeleton-loader>
                    </v-col>
                    <v-col cols="12">
                        <v-skeleton-loader type="image,image" style="height: 260px; overflow-y: hidden;"></v-skeleton-loader>
                    </v-col>
                </v-row>
            </v-col>

            <!-- <v-col cols="4" align="center">
                <StatList statistic_title_name="Top Quarantined Emails" :value="quarantined_emails"></StatList>
            </v-col> -->
        </v-row>
    </v-container>
</template>

<script>
    import BaseChart from '../StatisticComponents/Charts/BaseChart.vue';
    // import TooltipDescription from '../UtilityComponents/TooltipDescription.vue';
    import MainPageStat from '../StatisticComponents/MainPageStat.vue';
    import api_caller from "@/javascript/chart_config_retrieval.js";
    import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_ERROR } from "@/constants/constants.js";

    export default {
        name: 'EmailMonitoring',
        props: [
        ],
        data: () => ({
            alertMsg: null,
            alertType: null,
            showAlert: false,
            email_data_raw: null,
            recent_alerts: [],
            threats_by_user: [],
            quarantined_emails: [],
            top_email_threats: [],

            current_page: 'overview',

            email_activity_past_week: null,
            rejections_by_type: null,
            email_traffic: null,

            held_emails: null,
            bounced_emails: null,
            rejected_emails: null,
            charts_config: null
        }),
        components: {
            MainPageStat,
            // TooltipDescription,
            BaseChart
        },
        mounted(){
            this.getChartConfig()
        },
        methods: {
            getChartConfig: async function () {
                const token = await this.$auth.getTokenSilently();
                let charts_config = await api_caller
                    .get_email_monitoring_charts_config(token)
                    .then((resp) => {
                    if (resp["success"]) {
                        return resp["data"];
                    } else {
                        return false;
                    }
                    })
                    .catch((e) => {
                    console.log(e);
                    return false;
                    });

                if (charts_config) {
                    this.charts_config = charts_config
                    this.email_activity_past_week = this.charts_config['data']['EmailActivityPastWeek'];
                    this.rejections_by_type = this.charts_config['data']['RejectionsByType'];
                    this.email_traffic = this.charts_config['data']['EmailTraffic'];
                    this.held_emails = this.charts_config['data']['HeldEmails']['stat_value'];
                    this.rejected_emails = this.charts_config['data']['RejectedEmails']['stat_value'];
                    this.bounced_emails = this.charts_config['data']['BouncedEmails']['stat_value'];

                } else {
                    this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
                }
            },
            triggerAlert: function (type, message) {
                this.alertType = type ? type : "info";
                this.alertMsg = message;
                this.showAlert = true;
            },
        }
    }
</script>

<style scoped>
    .last_updated_title{
        font-family: "Gothic Bold" !important;
        font-size: 0.8em !important;
    }
    .last_updated_text{
        font-family: "Gothic" !important;
        font-size: 0.85em !important;
    }
    .page-title{
        font-family: "Gothic Bold" !important;
        font-size: 25pt !important;
    }
    .page-link{
        font-family: "Gothic" !important;
        font-size: 15pt !important;
        margin-left: 10px;
        color: #66B85A;
    }
    .data_breakdown_chip {
        font-family: "Gothic Bold" !important;
        /* color: black !important; */
        font-weight: 600;
        width: 175px;
        justify-content: center;
        margin-left: 1px;
        margin-right: 1px;
    }
    .theme--light.v-chip {
        color: white;
    }
</style>