<template>
    <v-row class="ma-0 pa-1 statistic_box" v-if="emphasized" align="center" justify="center">
        <v-col class="pt-6 pb-1" cols="12" align="center" >
            <span class="statistic_value_text_white">
                {{ ((value == null ? statistic_value : value) + (percentage ? "%": "")) }}
                <v-icon v-if="change > 0" color="white" large>mdi-arrow-up</v-icon>
                <v-icon v-else-if="change < 0" color="white">mdi-arrow-down</v-icon>
            </span>
        </v-col>
        <v-col class="pt-1" cols="12" align="center">
            <span class="statistic_title_white">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
    <v-row class="ma-0 pa-0" v-else>
        <v-col class="pb-1" cols="12" align="center">
            <span class="statistic_value_text">
                {{ (value == null ? statistic_value : value)}}
                <v-icon v-if="change > 0" color="green">mdi-arrow-up</v-icon>
                <v-icon v-else-if="change < 0" color="red">mdi-arrow-down</v-icon>
            </span>
        </v-col>
        <v-col cols="12" align="center">
            <span class="statistic_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: 'MainPageStat',
        props: [
            'statistic_title_name',
            'value',
            'change',
            'emphasized'
        ],
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            percentage: true
        })
    }
</script>

<style scoped>
    .statistic_value_text{
        font-family: "Gothic Bold";
        font-size: 30pt;
    }
    .statistic_value_text_white{
        font-family: "Gothic Bold";
        font-size: 30pt;
        color: #FFFFFF;
    }
    .statistic_title{
        font-family: "Gothic";
        font-size: 10pt;
    }
    .statistic_title_white{
        font-family: "Gothic";
        font-size: 10pt;
        color: #FFFFFF;
        display: block;
        height: 50px;
    }
    .statistic_box{
        background-color: #66B85A;
        border-radius: 50%;
        min-height: 200px;
        min-width: 200px;
        max-height: 200px;
        max-width: 200px;
    }
</style>