<template>
    <v-container class="ma-0 pa-3" style="background-color: #1b1b1b; width: 100%; height: 100%;" fluid>
        <v-row class="ma-0 pa-0" style="background-color: #1b1b1b; width: 100%; height: 100%;">
            <v-spacer></v-spacer>
            <v-col cols="auto" align-self="center">
                <v-btn outlined x-large class="ma-4 loginFormTextStyle" color="third" @click="login">Log In</v-btn>
            </v-col>
            <v-spacer></v-spacer>
        </v-row>
    </v-container>
</template>

<script>
export default {
    name: "LoginPage",
    data() {
        return {

        };
    },
    mounted() {
        if(this.$auth.isAuthenticated) {
            this.$router.push("/home");
        }
    },
    methods: {
        login() {
            this.$auth.loginWithRedirect();
        },
        // Log the user out
        logout() {
            this.$auth.logout({
                logoutParams: {
                    returnTo: window.location.origin,
                },
            });
        }
    }
 };
</script>

<style scoped>
    .loginFormTextStyle {
        font-family: "Gothic Bold" !important;
    }
</style>