<template>
    <v-row class="ma-0 pa-0">
        <v-col class="ma-0 pa-0" cols="auto">
            <span class="chart_title">{{ statistic_title_name == null ? statistic_name : statistic_title_name }}</span>
        </v-col>
        <v-spacer></v-spacer>
        <v-col class="ma-0 pa-0" cols="auto">
            <v-btn icon v-if="link">
                <v-icon color="secondary" @click="open_further_details">mdi-magnify-expand</v-icon>
            </v-btn>
        </v-col>
        <v-col cols="12">
            <GChart
                class="chart_style"
                type="BarChart"
                :options="options"
                :data="data"></GChart>
        </v-col>
    </v-row>
</template>

<script>
    import { GChart } from "vue-google-charts/legacy";
    export default {
        name: 'BarChart',
        props: [
            'statistic_title_name',
            'value',
            'link'
        ],
        components: {
            GChart
        },
        data: () => ({
            statistic_value: '-',
            statistic_name: 'Statistic',
            data: [
                ['Threat','Quantity'],
                ['Threat 1', 50],
                ['Threat 2', 40],
                ['Threat 3', 60],
                ['Threat 4', 55],
                ['Threat 5', 60],
            ],
            options: {
                "legend": { "position": "none" },
                "chartArea": {"margin":"0 0", "top": "2%", "bottom": "5%", "right": 0, "left": "35%"},
                "isStacked": false,
                "vAxis": {
                    "minValue": 0,
                },
                "tooltip": {
                    "trigger": "none"
                },
                "colors": [
                    "#66B85A", 
                    "#FF6F61",  
                    "#6A5ACD", 
                    "#FFA07A",  
                    "#20B2AA",  
                    "#FFD700",  
                    "#4682B4",  
                    "#FF6347",  
                    "#8A2BE2",  
                    "#FF4500"  
                ],
                "fontName": "Gothic"
            }
        }),
        watch: {
            value: function(){
                if(this.value != null){
                    this.data = this.value;
                }
            }
        },
        mounted(){
            if(this.value != null){
                this.data = this.value;
            }
        },
        methods: {
            open_further_details: function(){
                if(this.link){
                    this.$router.replace(this.link);
                }
            }
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chart_style{
        height: 200px;
        font-family: "Gothic" !important;
    }
</style>