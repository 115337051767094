<template>
  <v-app>
    <v-app-bar app color="third" v-if="$auth.isAuthenticated && !$route.meta.hideNavbar">
      <div class="d-flex align-center" style="margin: 30px">
        <v-img
          :src="require('./assets/QuickProtect_Logo.svg')"
          class="my-3"
          contain
          height="200"
          to="/#/"
        />
      </div>

      <v-spacer></v-spacer>
      <v-btn outlined class="ma-0 mr-6 docma-text-navigation-bar-btn" color="secondary" :disabled="build_export || true">
        <v-icon class="mx-2" color="secondary">mdi-refresh</v-icon>
        Refresh Data
      </v-btn>
      <!-- <v-btn outlined class="ma-0 mr-6 docma-text-navigation-bar-btn" color="secondary" @click="build_export=true;" :disabled="build_export">
        <v-icon class="mx-2" color="secondary">mdi-file-export</v-icon>
        Build Export
      </v-btn> -->
      <v-btn
        class="ma-0 pa-0 mr-6 docma-text-navigation-bar-btn"
        text
        plain
        dark
        @click="user_drawer = true"
      >
        <span class="docma-text-navigation-bar-text"
          >Welcome {{ this.$auth.user.name.split(" ")[0] }}</span
        >
        <v-icon class="mx-2" color="secondary">mdi-account-outline</v-icon>
        <!-- <v-avatar v-if="$auth.isAuthenticated && $auth.user.picture" class="ma-0 pa-0 ml-1">
            <img
              :src="this.$auth.user.picture"
              :alt="this.$auth.user.name"
            >
          </v-avatar>
          <v-avatar v-else class="ma-0 pa-0 ml-1" color="green">
            <span class="white--text text-h5">{{ getUserInitial() }}</span>
          </v-avatar> -->
      </v-btn>

      <template v-slot:extension>
        <v-row class="ma-0 pa-0">
          <v-col cols="auto" class="ma-0 pa-0 px-3" style="max-height: 48px">
            <v-tabs align-tabs="start" color="secondary">
              <v-tab class="docma-tool-navigation-bar" to="/home" :disabled="build_export">Home</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/ticketing" :disabled="build_export">Ticketing</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/endpoint_protection" :disabled="build_export">Endpoint Protection</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/device_monitoring" :disabled="build_export">Device Monitoring</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/email_monitoring" :disabled="build_export">Email Monitoring</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/questionnaire" :disabled="build_export">Questionnaire</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/vCISO" :disabled="build_export">vCISO</v-tab>
              <v-tab class="docma-tool-navigation-bar" to="/export">Export</v-tab>
            </v-tabs>
          </v-col>
        </v-row>
      </template>
    </v-app-bar>

    <!-- Test Data Alert Dialog -->
    <v-dialog v-model="showTestDataDialog" max-width="400">
      <v-card>
        <v-card-title class="callout-headline">Welcome!</v-card-title>
        <v-card-text class="callout-text">
          Welcome to the QuickProtect Cyber Security Platform. <br />
          Please be aware that this tool is still in development and currently using test data.<br />
          Please reach out to the Docma team regarding any errors you may encounter<br />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="#66B85A" @click="showTestDataDialog = false">Okay</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-navigation-drawer
      v-if="$auth.isAuthenticated && !$route.meta.hideNavbar"
      v-model="user_drawer"
      temporary
      app
      color="third"
      dark
      right
      width="300"
    >
      <template v-slot:prepend>
        <div v-if="showAlert" style="position:absolute; z-index: 99;">
            <v-alert
                v-model="showAlert"
                border="left"
                close-text="Close Alert"
                dismissible
                :type="alertType"
            >
                {{ alertMsg }}
            </v-alert>
        </div>
        <v-list-item one-line>
          <v-list-item-avatar v-if="$auth.isAuthenticated && $auth.user.picture">
            <img :src="$auth.user.picture" :alt="$auth.user.name" />
          </v-list-item-avatar>
          <v-list-item-avatar v-else class="ma-0 pa-0 ml-1" color="green">
            <span class="white--text text-h5">{{ getUserInitial() }}</span>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-drawer-text">{{
              $auth.user.name
            }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6" color="#66B85A"></v-divider>
        <v-list-item one-line>
          <v-list-item-avatar
            ><v-icon color="black">mdi-account-group-outline</v-icon></v-list-item-avatar
          >

          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-drawer-text">
              <div class="organization-title">
                <div class="organization-title-content">
                  {{
                    user_orgs && user_orgs[$auth.user.org_id]
                      ? user_orgs[$auth.user.org_id]["display_name"]
                      : $auth.user.org_id
                  }}
                </div>
                <v-tooltip left v-if="Object.keys(user_orgs).length > 1">
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="switchOrg">
                      <v-icon dark color="#66B85A">mdi-autorenew</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Switch Organization
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="ma-0 pa-0 mx-6" color="#66B85A"></v-divider>
        <v-list-item one-line>
          <v-list-item-content>
            <v-list-item-title class="docma-text-navigation-drawer-text">
              <div class="user_preferences_title">
                <div class="user-preferences-content">Home Page Settings</div>
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-btn icon class="toolButton" v-on="on" @click="update_user_preferences">
                      <v-icon dark color="#66B85A">mdi-content-save</v-icon>
                    </v-btn>
                  </template>
                  <span class="tooltip_description" style="display: inline-block">
                    Save Home Page Settings
                  </span>
                </v-tooltip>
              </div>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item one-line>
          <v-row class="ma-0 pa-0">
            <v-col cols="12" class="ma-0 pa-0">
              <v-expansion-panels flat v-model="panel" light multiple>
                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-3 home-page-settings-title">Data Points</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="data_point_options"
                          label="Data point 1"
                          :item-value="'value'"
                          :item-text="'label'"
                          v-model="preference_data_point_1"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="data_point_options"
                          label="Data point 2"
                          :item-value="'value'"
                          :item-text="'label'"
                          v-model="preference_data_point_2"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="data_point_options"
                          label="Data point 3"
                          :item-value="'value'"
                          :item-text="'label'"
                          v-model="preference_data_point_3"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="data_point_options"
                          label="Data point 4"
                          :item-value="'value'"
                          :item-text="'label'"
                          v-model="preference_data_point_4"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>

                <v-expansion-panel>
                  <v-expansion-panel-header class="pl-3 home-page-settings-title">Graphs</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <v-row class="ma-0 pa-0">
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="graph_options"
                          :item-value="'value'"
                          :item-text="'label'"
                          label="Graph 1"
                          v-model="preference_graph_1"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="graph_options"
                          :item-value="'value'"
                          :item-text="'label'"
                          label="Graph 2"
                          v-model="preference_graph_2"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="graph_options"
                          :item-value="'value'"
                          :item-text="'label'"
                          label="Graph 3"
                          v-model="preference_graph_3"
                        ></v-select>
                      </v-col>
                      <v-col cols="12">
                        <v-select
                          class="home-page-settings-action"
                          light
                          dense
                          :items="graph_options"
                          :item-value="'value'"
                          :item-text="'label'"
                          label="Graph 4"
                          v-model="preference_graph_4"
                        ></v-select>
                      </v-col>
                    </v-row>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-col>
          </v-row>
        </v-list-item>
      </template>
      <template v-slot:append>
        <div class="pa-2">
          <v-btn
            color="secondary"
            class="docma-text-navigation-logout"
            block
            @click="logout"
          >
            Logout
          </v-btn>
        </div>
      </template>
    </v-navigation-drawer>

    <v-main>
      <router-view v-if="!build_export" />
      <export v-else v-on:export_built="export_built"></export>
    </v-main>
  </v-app>
</template>

<script>
// import BasePage from './components/BasePage';
import org_api_caller from "@/javascript/user_org_retrieval";
import api_caller from "@/javascript/chart_config_retrieval";
import { ALERT_GENERIC_MESSAGE, ALERT_TYPE_SUCCESS, ALERT_TYPE_ERROR, SUCCESS_USER_PREFERENCES_MESSAGE } from "@/constants/constants.js";
import { ref } from 'vue'

import Export from "./components/Sections/Export.vue";

const GRAPH_PREFERENCE_OPTIONS = ref([
    { divider: true },
    { header: 'Ticketing' },
    { divider: true },
    { label: "Tickets By Priority", value: "TicketsByPriority" },
    { label: "Tickets By Status", value: "TicketsByStatus" },
    { divider: true },
    { header: 'Endpoint Protection' },
    { divider: true },
    { label: "Threats By Type", value: "ThreatsByType" },
    { label: "Threats By Detection Engine", value: "ThreatsByDetectionEngine" },
    { label: "Threats By Status", value: "ThreatsByStatus" },
    { label: "Threats By Analyst Verdict", value: "ThreatsByAnalystVerdict" },
    { divider: true },
    { header: 'Device Monitoring' },
    { divider: true },
    { label: "Antivirus Status", value: "AntivirusStatus" },
    { label: "Offline Devices By Type", value: "OfflineDevicesByType" },
    { divider: true },
    { header: 'Email Monitoring' },
    { divider: true },
    { label: "Email Activity In Past Week", value: "EmailActivityInPastWeek" },
    { label: "Email Traffic", value: "EmailTraffic" },
    { label: "Rejections By Type", value: "RejectionsByType" },
]);

const DATA_POINT_PREFERENCE_OPTIONS = ref([
    { divider: true },
    { header: 'Ticketing' },
    { divider: true },
    { label: "Open Tickets", value: "OpenTickets" },
    { label: "High Priority Tickets", value: "HighPriorityTickets" },
    { divider: true },
    { header: 'Endpoint Protection' },
    { divider: true },
    { label: "Total Threats", value: "TotalThreats" },
    { label: "Unresolved Threats", value: "UnresolvedThreats" },
    { divider: true },
    { header: 'Device Monitoring' },
    { divider: true },
    { label: "Offline Devices", value: "OfflineDevices" },
    { label: "Total Devices", value: "TotalDevices" },
    { divider: true },
    { header: 'Email Monitoring' },
    { divider: true },
    { label: "Bounced Emails", value: "BouncedEmails" },
    { label: "Rejected Emails", value: "RejectedEmails" },
    { label: "Held Emails", value: "HeldEmails" },
    { label: "Total Emails", value: "TotalEmails" },
]);


export default {
  name: "App",

  components: {
    // BasePage,
    Export
  },
  mounted() {
    document.title = "CyberProtection. Simplified.";
    if (this.$auth.isAuthenticated) {
      this.showTestDataDialog = true; // Show dialog on login if authenticated
    }
  },

  data: () => ({
    user_drawer: false,
    user_orgs: {},
    data_point_options: DATA_POINT_PREFERENCE_OPTIONS,
    graph_options: GRAPH_PREFERENCE_OPTIONS,

    alertMsg: null,
    alertType: null,
    showAlert: false,

    panel: false,

    preference_data_point_1: null,
    preference_data_point_2: null,
    preference_data_point_3: null,
    preference_data_point_4: null,

    preference_graph_1: null,
    preference_graph_2: null,
    preference_graph_3: null,
    preference_graph_4: null,

    build_export: false,

    showTestDataDialog: false,
    brands_pulled: false
  }),

  updated() {
    if (this.$auth.isAuthenticated && !this.brands_pulled) {
      this.showTestDataDialog = true;
      this.get_my_orgs();
      this.get_user_preferences();
    }
  },

  methods: {
    logout: function () {
      this.$auth.logout({
        logoutParams: {
          returnTo: window.location.origin,
        },
      });
    },
    getUserInitial: function () {
      let initial = "";
      let name = this.$auth.user.name;
      if (name) {
        let splitted = name.split(" ");
        initial = splitted[0].substring(0, 1).toUpperCase();

        if (splitted.length > 1) {
          initial += splitted[splitted.length - 1].substring(0, 1).toUpperCase();
        }
      }
      return initial;
    },
    get_my_orgs: async function () {
      let token = await this.$auth.getTokenSilently();
      let userOrgs = await org_api_caller
        .get_user_orgs(token, this.$auth.user.sub)
        .then((resp) => {
          if (resp["success"]) {
            return resp["data"];
          }
          return false
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (userOrgs) {
        this.user_orgs = userOrgs;
      }
    },
    switchOrg: function () {
      this.$auth.loginWithRedirect();
    },
    triggerAlert: function (type, message) {
        this.alertType = type ? type : "info";
        this.alertMsg = message;
        this.showAlert = true;
    },
    get_user_preferences: async function() {
      let token = await this.$auth.getTokenSilently();
      let userPreferences = await api_caller
        .get_homepage_user_preferences(token)
        .then((resp) => {
          if (!resp["success"]) {
            this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
          }
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if (userPreferences) {
        let data_pref = userPreferences['data_points_stat']
        let graph_pref = userPreferences['graphs_stat']

        this.preference_data_point_1 = data_pref[0];
        this.preference_data_point_2 = data_pref[1];
        this.preference_data_point_3 = data_pref[2];
        this.preference_data_point_4 = data_pref[3];

        this.preference_graph_1 = graph_pref[0];
        this.preference_graph_2 = graph_pref[1];
        this.preference_graph_3 = graph_pref[2];
        this.preference_graph_4 = graph_pref[3];
        this.brands_pulled = true;
      } 
    },
    update_user_preferences: async function() {
      let payload = {
        config: {
          numeric_statistic_a: this.preference_data_point_1,
          numeric_statistic_b: this.preference_data_point_2,
          numeric_statistic_c: this.preference_data_point_3,
          numeric_statistic_d: this.preference_data_point_4,
          graph_statistic_a: this.preference_graph_1,
          graph_statistic_b: this.preference_graph_2,
          graph_statistic_c: this.preference_graph_3,
          graph_statistic_d: this.preference_graph_4
        }
      }

      let token = await this.$auth.getTokenSilently();
      let userPreferences = await api_caller
        .update_homepage_user_preferences(token, payload)
        .then((resp) => {
          return resp["data"];
        })
        .catch((e) => {
          console.log(e);
          return false;
        });
      if(userPreferences && userPreferences['rows_updated'] > 0) {
        this.triggerAlert(ALERT_TYPE_SUCCESS, SUCCESS_USER_PREFERENCES_MESSAGE);
        window.location.reload();
      } else {
        this.triggerAlert(ALERT_TYPE_ERROR, ALERT_GENERIC_MESSAGE);
      }
    },
    export_built: function(){
      this.build_export=false;
    }
  },
};
</script>

<style>
@font-face {
  font-family: "Gothic";
  src: url("./assets/fonts/GOTHIC_0.TTF");
}
@font-face {
  font-family: "Gothic Bold";
  src: url("./assets/fonts/GOTHICB_0.TTF");
}
.docma-tool-navigation-bar {
  font-family: "Gothic Bold";
  font-size: 0.9em;
  color: black;
  text-transform: none !important;
}
.docma-text-navigation-bar-text {
  font-family: "Gothic";
  font-size: 0.9em;
  color: black;
}
.docma-text-navigation-drawer-text {
  font-family: "Gothic";
  font-size: 1.1em;
  color: black;
}
.docma-text-navigation-bar-btn {
  height: 30px !important;
}
.docma-text-navigation-logout {
  font-family: "Gothic Bold";
  font-size: 0.9em;
  color: white;
}

.docma-text-navigation-user {
  font-family: "Gothic Bold" !important;
  font-weight: 200 !important;
  font-size: 1.1em !important;
}

.organization-title {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 0.7em !important;
  color: black;
  display: flex;
  max-width: 200px;
  align: left;

  .organization-title-content {
    max-width: 150px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    margin: auto;
  }
}

.home-page-settings-action {
  font-family: "Gothic" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
  width: 196px;
}
.home-page-settings-title {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 0.9em !important;
}


.callout-headline {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 1.1em !important;
}
.callout-text{
  font-family: "Gothic" !important;
  font-weight: 400 !important;
  font-size: 1em !important;
}

.user_preferences_title {
  font-family: "Gothic Bold" !important;
  font-weight: 400 !important;
  font-size: 0.85em !important;
  color: black;
  display: flex;
  margin: 0 12px 0 8px;

  .user-preferences-content {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex: auto;
    margin: auto;
  }
}

.organization-content {
  font-size: 1em !important;
  padding-bottom: 12px;
}

.v-alert {
  position: fixed;
  left: 50%;
  /* bottom: 50px; */
  width: 100%;
  transform: translate(-50%, 0%);
  z-index: 2;
  /* margin: 0 0; */
}
</style>
