<template>
    <v-row class="ma-0 pa-0">
        <v-col cols="12" v-if="chart_type=='BarChart'">
            <BarChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
            />
        </v-col>
        <v-col cols="12" v-else-if="chart_type=='DualTimeSerieChart'">
            <DualTimeSerieChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
            />
        </v-col>
        <v-col cols="12" v-else-if="chart_type=='PieChart'">
            <PieChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
                :chart_ref="chart_ref"
            ></PieChart>
        </v-col>
        <v-col cols="12" v-else-if="chart_type=='StackedBarChart'">
            <StackedBarChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
            />
        </v-col>
        <v-col cols="12" v-else-if="chart_type=='TimeSeriesChart'">
            <TimeSerieChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
            />
        </v-col>
        <v-col cols="12" v-else-if="chart_type=='TwoSidedBarChart'">
            <TwoSidedBarChart
                :statistic_title_name="statistic_title_name"
                :link="link"
                :value="value"
            />
        </v-col>
    </v-row>
</template>

<script>
    import PieChart from "./PieChart.vue";
    import BarChart from "./BarChart.vue";
    import DualTimeSerieChart from "./DualTimeSerieChart.vue"
    import StackedBarChart from "./StackedBarChart.vue"
    import TimeSerieChart from "./TimeSerieChart.vue"
    import TwoSidedBarChart from "./TwoSidedBarChart.vue"

    export default {
        name: 'BaseChart',
        props: [
            'statistic_title_name',
            'value',
            'link',
            'chart_type',
            'chart_ref'
        ],
        components: {
            BarChart,
            DualTimeSerieChart,
            PieChart,
            StackedBarChart,
            TimeSerieChart,
            TwoSidedBarChart
        }
    }
</script>

<style scoped>
    .chart_title{
        font-family: "Gothic Bold" !important;
        font-size: 10pt !important;
        line-height: 40px;

        display: block;
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .chart_style{
        height: 200px;
        font-family: "Gothic" !important;
    }
</style>