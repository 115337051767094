import axios from 'axios'

// const api_endpoint = 'http://127.0.0.1:5001'
const api_endpoint = 'https://csp.api.docmasweetspot.ca'


function get_questionnaire_score(token){
    let api_url = api_endpoint+'/home/get_questionnaire_score';
    return axios_post_call(api_url,{},token);
}

/*
Base Caller functions
*/
function axios_post_call(api_url,data,token){
    return new Promise(function(resolve,reject){
        axios.post(api_url, data, {headers: {Authorization: `Bearer ${token}`}}).then((res)=>{
            if(res.status == 200){
                resolve(res.data);
            }
            else{
                reject();
            }
        }).catch((e)=>{
            reject(api_url+e);
        })
    })
}

export default {
    get_questionnaire_score
}