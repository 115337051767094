import { render, staticRenderFns } from "./DeviceMonitoring.vue?vue&type=template&id=0c0a570b&scoped=true"
import script from "./DeviceMonitoring.vue?vue&type=script&lang=js"
export * from "./DeviceMonitoring.vue?vue&type=script&lang=js"
import style0 from "./DeviceMonitoring.vue?vue&type=style&index=0&id=0c0a570b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c0a570b",
  null
  
)

export default component.exports