import jsPDF from 'jspdf'
import 'jspdf-autotable'
import * as export_helper from "./exportHelper";

class PDFBuilder {
    constructor() {
        this.pdf_elements = {'graphs':{}, 'tables': {}, 'data_points': {}}
        this.pdf = new jsPDF("w", "mm", "a4");
        this.pdf_width = this.pdf.internal.pageSize.getWidth();
        this.pdf_height = this.pdf.internal.pageSize.getHeight();
        this.pdf_x_break = this.pdf_width / 20;
        this.pdf_y_break = this.pdf_height / 20;
        this.pdf_content_height = this.pdf_height - 24 - ((3 * this.pdf_x_break) / 4);
        this.pdf_cur_y = 0;
        this.pdf_name = 'QuickProtect.pdf';
        this.color_primary = '#00285e';
        this.color_secondary = '#0079C1';
        this.total_elements = 4;
        this.num_elements_on_page = 0;
    }
    get_pdf_name() {
        return this.pdf_name;
    }
    set_pdf_name(pdf_name) {
        if (pdf_name != '' && pdf_name != null) {
            this.pdf_name = pdf_name;
        }
    }
    add_pdf_element(elementName, element, elementType) {
        if(elementType == 'graph')
            this.pdf_elements['graphs'][elementName] = element;
        else if(elementType == 'table')
            this.pdf_elements['tables'][elementName] = element
        else if(elementType == 'data')
            this.pdf_elements['data_points'][elementName] = element
    }
    build(){
        // build all datapoint elements
        let text = ''
        this.updateCurY(20);
        for (let data in this.pdf_elements['data_points']) {
            text = this.pdf_elements['data_points'][data]['stat_title'] + ': ' + this.pdf_elements['data_points'][data]['stat_value']
            this.pdf.text(text, 20, this.pdf_cur_y);
            this.updateCurY(10);
            this.num_elements_on_page ++
        }

        // build all charts
        for (let chart in this.pdf_elements['graphs']) {
            this.pdf.addImage(this.pdf_elements['graphs'][chart]['image'], 0, this.pdf_cur_y, 200, 40);
            this.updateCurY(50);
            this.num_elements_on_page ++
        }

        // build all tables
        for (let chart in this.pdf_elements['tables']) {
            this.add_stat_table(this.pdf_elements['tables'][chart])
            this.num_elements_on_page ++
        }
    }

    add_stat_table(element){
        let data_head = [export_helper.build_table_header(element['content'].header)]
        let data_body = export_helper.build_table_body(element['content'].data, data_head[0]);
        this.updateCurY(this.pdf_y_break);
        this.pdf.autoTable({startY: this.pdf_cur_y, tableWidth: 150, head: data_head, body: data_body, headStyles: {fillColor: this.color_primary, fontStyle: "bold"}, styles: {fontStyle: "normal"}});
        this.updateCurY((this.pdf.previousAutoTable.finalY - this.pdf_cur_y));
    }

    updateCurY(increaseBy){
        if(((this.pdf_cur_y + increaseBy) > (this.pdf_height - 2*this.pdf_y_break - ((this.pdf_x_break * 3)/4) - 34 )) && this.num_elements_on_page != this.total_elements){
            this.pdf.addPage();
            this.pdf.setPage(this.pdf.getNumberOfPages());
            this.pdf_cur_y = 0;
            // this.add_main_logo();
            // this.add_footer();
        }
        else{
            this.pdf_cur_y += increaseBy;
        }
    }
    add_footer(){
        let yLoc = this.pdf_height - 24;
        this.pdf.setFontSize(8);
        this.pdf.text(['*Applied Post, DeviceIDs in Specified Geofences, Jan 1, 2020 - March 30, 2021.','**Environics was a collection of different reports including: FoodSpend_2019_CSV, CannabisInsights_2018_PSV, MoneyMatters_2020_CSV, Opticks_Vividata_2020_CSV, PRIZM_2020_v1_CSV_GeoProfiles,','and HouseholdSpend_2020_CSV', '***Statcan, Annual demographic estimates, economic regions, \'Toronto\', 2018/2019; https://www150.statcan.gc.ca/n1/pub/71-607-x/71-607-x2020020-eng.html'],10,yLoc,{lineHeightFactor: 1.1, maxWidth: this.pdf_width - 20});
    }
    save(){
        this.pdf.save(this.pdf_name);
    }
}export default PDFBuilder